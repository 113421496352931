@import 'mixin_breakpoints';
/**********************************************************************
	From BootStrap v4 alpha (the above mixin_breakspoints file)
	@include media-breakpoint-up(xs) { ... } //don't use xs. It is the default, so it doesn't end up wrapped in a media query
	@include media-breakpoint-up(sm) { ... }
	@include media-breakpoint-up(md) { ... }
	@include media-breakpoint-up(lg) { ... }
	@include media-breakpoint-up(xl) { ... }
	
	@include media-breakpoint-down also exists for each of the above.
***********************************************************************/



/****************************************************************************************
	FONTS	
*****************************************************************************************/
body{ font-family: 'Source Sans Pro', sans-serif; font-size:13px; line-height:1.4; 
	@include media-breakpoint-up(sm) { font-size:16px; }
	@include media-breakpoint-up(lg) { font-size:20px; }
}

h1,h2,h3,h4,h5,h6,
.btn,
#homeIconNav{ font-family: 'Roboto Slab', serif; }

h3{ font-size:1.25em; }
header{ font-weight:600; }
footer{ font-weight:300;
	p{ font-size: .8em; }
}

.heroContainer{
	h1{ font-size:1em; font-weight:700; line-height: 1.2; }
	@include media-breakpoint-only(sm){ h1{ font-size:1.4em; } }
	@include media-breakpoint-only(md){ h1{ font-size:1.75em; } }
	@include media-breakpoint-only(lg){ h1{ font-size:2em; } }
	@include media-breakpoint-only(xl){ h1{ font-size:2.5em; } }
}

.exampleText{
	h3{ font-weight:300; }
	.btn{ font-weight:400; font-size:1.3em; 
		@include media-breakpoint-up(md){ font-weight:300; font-size:1em; }
	}
	p{ font-size:1.3em; font-weight:400; 
		@include media-breakpoint-only(sm){ font-size:1.1em; }
		@include media-breakpoint-up(md){ font-size:.9em; font-weight:300;  }
		@include media-breakpoint-up(lg){ font-size:.8em;  }
	}
}

#homeIconNav{
	h2,p{ font-weight:300; }
	h2{ font-size: 2em; 
		@include media-breakpoint-up(sm){ font-size:1.1em; }
	}
	p{ font-size:1.5em; 
		@include media-breakpoint-up(sm){ font-size:.9em; }
	}
}

.quote{
	h3{ font-size:1.5em; font-weight:300;
		@include media-breakpoint-only(xl){ font-size:1.8em; }
	} 
	blockquote{ font-weight:300; font-size:1.3rem; }
	blockquote:before,blockquote:after { font-family:'Roboto Slab'; font-weight:400; }
}




/****************************************************************************************
	COLORS	
	//primary
		// pantone 7468 c - blue 	- rgb(0,117,154)	- online courses and training
		// pantone cool gray 11  	- rgb(77,79,83)		
		// pantone cool gray 11 40%	- rgba(77,79,83,.4)	
	//secondary
		// pantone 185 c - red 		- rgb(228,0,43)
		// pantone 7405 c - yellow 	- rgb(236,194,0)	- custom content creation
		// pantone 325 c - blue		- rgb(99,206,202)	- eMediaVA
*****************************************************************************************/
$primaryColor1: rgb(0,117,154);
$primaryColor2: rgb(77,79,83);
$primaryColor3: #C2C3C5; /* having this as rgba actually causes transparency issues with the separator on the secondary pages */

$secondaryColor1: rgb(228,0,43);
$secondaryColor2: rgb(236,194,0);
$secondaryColor3: rgb(99,206,202);

	body{ background-color:$primaryColor3; }
	.container{ 
		background-color:#fff; color:$primaryColor2;
		&>header{ background:rgba(255,255,255,.6); 
			a{ color:$primaryColor2; }
		}
		&>footer{ background:$primaryColor1; 
			p,a{ color:#fff; }
		}
	}
	
	.darkText{ color:$primaryColor2 !important; } /* This is used as an override. Should be rarely used */
	
	#homeIconNav{
		a{ color:$primaryColor2; }
		.iconNavOnlineCourses{ 
			h2{ background-color:rgba(0,117,154,.25); } 
			p{ border-color:rgba(0,117,154,.25); }
		}
		.iconNavCustomContent{ 
			h2{ background-color:rgba(236,194,0,.25); } 
			p{ border-color:rgba(236,194,0,.25); }
		}
		.iconNavEMedia{ 
			h2{ background-color:rgba(99,206,202,.25); } 
			p{ border-color:rgba(99,206,202,.25); }
		}
	}
	
	.btn-primary,
	.btn-primary:hover,
	.btn-primary:focus, 
	.btn-primary:active{ color: #fff; background-color: transparent; border-color: $secondaryColor1; border-radius:0; position:relative;
		    transition-property: color;transition-duration: 0.5s;
		    transform: translateZ(0); /* This enables hardware acceleration on mobile/tablet */
			box-shadow: 0 0 1px rgba(0, 0, 0, 0); /* On many mobile/tablet devices, using transforms can cause a thin line to be drawn around an element. This deals with that. */
		/* Hover effect that slides left to right */
		&:before{ content: ""; position: absolute; z-index: -1; top: 0; left: 0; right: 0; bottom: 0; background: $secondaryColor1;    
		    transform: scaleX(0);
		    transform-origin: 0 50%;
		    transition-property: transform;
		    transition-duration: 0.5s;
		    transition-timing-function: ease-out;
		}
		&:hover:before, &:focus:before, &:active:before {
		    transform: scaleX(1);
		    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
		}	
		/* End Hover effect */
		
		&:hover.darkText{ color:#fff !important; }
	}
	.btn-secondary{ color: $primaryColor2; background-color: transparent; border-color: $primaryColor2; border-radius:0; }
	
	.bkgdPrimary2{ background:$primaryColor2; }
	.bkgdPrimary3{ background:$primaryColor3; }
	.bkgdOnlineCourses{ background:$primaryColor1; color:#fff; 
		a{ color:#fff; }
	}
	.bkgdCustomContent{ background:$secondaryColor2; }
	.bkgdEmediaVA{ background:$secondaryColor3; }
	.quote{ background-color:$primaryColor2; color:#fafafa; }
	
	.exampleText{ color:#fff; }
	@include media-breakpoint-up(md){ 
		.bounce.expanded .exampleText,
		.bounce.expanded .darkText{ color:#fff !important; }
	}

	.separator{ 
		span{ 
			img{ background:$primaryColor1; border:2px solid $primaryColor1; }
		}
		.bkgdPrimary3+span img{ background:$primaryColor3; border:2px solid $primaryColor3; }
	}


/***
	Flexbox
	- Used for the course list page
 ***/
.flexRow{ display: flex; flex-wrap: wrap;
	& > [class^='col-']{ display:flex; flex-direction:column; }
}

/****************************************************************************************
	Other Stuff	
*****************************************************************************************/
@media (min-width: 1200px){ .container{ max-width: 1200px; } }
img{ max-width:100%; }
select{ height:38px }
.alert{ margin-top:1rem; }
.zindex{ position:relative; z-index:10; }

ul{ list-style:none; padding:0; }
.list-dashed{
	li:before{ content: "-\00a0"; } /* \00a0 is just a non-breaking space */
}
form{margin-bottom:5rem;}

/* 
	PAGE HEADER / FOOTER	
*/
.container{ 
	position:relative; min-width:320px; 
	&>header,&>footer{ padding:.7em 0; }
	&>header{ position:absolute; width:100%; z-index:10;
		
		img{ width:100px; }
		@include media-breakpoint-up(md) { img{width:130px;} }
		@include media-breakpoint-up(lg) { img{width:auto;} }
		
		nav{ position:absolute; right:0; bottom:0;
			ul{ list-style: none; }
			li{ float:left; font-size:1.5em; line-height:1; margin-left: 2em; }
		}
	}
	&>footer{
		padding-top:1em;
		nav{ float:right; 
			ul{ list-style:none; text-align:right; }
		}
		p{ margin-top:2em; margin-bottom: 3em;}
	}
}

/* 
	HERO IMAGE	
*/
.heroContainer{ position:relative; 
	div{ position:absolute; top:55%; left: 0; }
}

/*
	SECTION SEPARATOR	
*/
.separator{ position:relative;
	hr{ height:.7em; margin: 0;}
	span{ height:2em; width: 2em; position: absolute; top: -.7em; left: 50%; margin-left: -1em;
		img{ border-radius:50%}
		svg{ width:100%; height:100%; }
	}
	.svgIcon{ position:absolute; top:-1.8em; left:50%; margin-left:-1.8em; width:3.6em; }
}

/*
	QUOTES	
*/
.quote{ 
	padding:1.1em 0 1.5em;
	@include media-breakpoint-up(sm) { padding:1.1em 2em 1.5em; }
	img{ margin-top:1em; width:30%;
		@include media-breakpoint-up(md) { margin-top:2em; width:20%; }
		@include media-breakpoint-only(xl) { margin-top:3em; width:auto; }
	}
	
	blockquote{ line-height:1.3; margin-top:.5em; position:relative; text-align:justify; }
	@include media-breakpoint-up(sm) { 
		blockquote{ padding: 0 2em; }
		/* The positions seem off here due to the increased font sizes and the quote mark being at the top of the given line (because it's a quote mark) */
		blockquote:before { content: "\201C"; font-size:5em; position: absolute; left:-2rem; top:-1.5rem; }
		blockquote:after { font-weight:400;float:right; content: "\201D"; font-size:5em; position: absolute; right:-2rem; bottom:-4rem;line-height: 1; }
	}
	cite{ display: block; text-align:right; }
	
	/* If the img is above the title, a couple of things change */
	&.quoteIconFirst{
		img{ margin-top:1.5em}
		h3{ font-size:1.3em; margin:1.4em 0 1.2em; }
	}
}


/*
	DESCRIPTIONS	
*/
#homeDescription{ margin-bottom:4em; }
.pageDescription{ line-height:1.6; margin-top:2em; margin-bottom:1.3em;
	h1{ font-size:1.8rem; margin-top:2rem }
	h2{ font-size:1.5rem; font-weight:300; margin-bottom:1.5em }
	p,ul{ margin-bottom:2rem; font-size:1.3rem; }
}


/*
	HOME ICON NAV
*/
#homeIconNav{ margin-bottom:3.4rem;
	a{ text-decoration: none; }
	h2{ margin:0; padding: .5em 0; 
		@include media-breakpoint-up(sm) { padding: 1em 0; }
	}
	p{ border-style: solid; border-width:1px; padding:.5em; }
	a:hover p{ text-decoration:underline}
	
	@include media-breakpoint-only(xs) {
		.iconNavOnlineCourses,
		.iconNavCustomContent,
		.iconNavEMedia{ padding:0; }
	}
	
	@include media-breakpoint-up(sm) {
		.iconNavOnlineCourses{ padding-left:0; }	
		.iconNavCustomContent{ padding:0 7px 0 8px; }
		.iconNavEMedia{ padding-right:0; }
	}
	
	@include media-breakpoint-only(xl) {.iconNavCustomContent p{padding:1.4rem} }
	@include media-breakpoint-down(lg) { .iconNavEMedia h2{ padding:1.5em; } }
	@include media-breakpoint-only(md) {
		.iconNavOnlineCourses,
		.iconNavCustomContent{ p{ padding-bottom:1.8em; }}
	}
	@include media-breakpoint-only(sm) {
		.iconNavOnlineCourses p{ padding-bottom:.5em; }
		.iconNavCustomContent p{ padding-bottom:3.3em; }
		.iconNavEMedia p{ padding-bottom:2em; }
	}
	
	svg{ width:20%; margin:0 auto 0.5em; 
		/* This is to get rid of the 4px spacing at the bottom of the svg. vertical-align:top; will also work if */
		display:block;  
		@include media-breakpoint-up(sm) { margin:0; width:70%; }
	}
}

/* 
	Additional Info sections
*/
.breakoutBox{ 
	background:url('/assets/images/bkgdBreakout.jpg') repeat 0 0 transparent; padding:2em 0; font-weight:300;
	h2{ color:#fff; font-size: 1.7rem; font-weight: 300; }
	.row{ background: #fff; border: 1px solid #444; padding:1.4em 2em 0em;
		.col-xs-4{ border-right:2px solid #eee; }
		ul{ margin-top:1.1em; }
		.col-xs-8 p{ margin-top:3rem; line-height:2rem; }
	}
}
.addlEmediaLinks{ padding-top: 2.5em; padding-bottom: 2em; font-size: 1.1em; font-weight: 300; 
	a{ color:$primaryColor2; border-bottom:1px solid $primaryColor2; text-decoration:none; font-size:.9em}
}
.addlEmedia{
	background:url('/assets/images/bkgdBreakout.jpg') repeat 0 0 transparent;
	p{ margin:2em 1.5em; background: #fff; padding:1.5em; opacity:.7}
	a{ color:$primaryColor2; border-bottom:1px solid $primaryColor2; text-decoration:none; }
}
.addlOnlineCourses{ font-size:1.3em; font-weight:300; 
	a{ text-decoration:underline; }
	p{ margin:2em 0; }
	.requestDemo p{ margin:1.5em 0; }
	
	@include media-breakpoint-up(sm) {
		p{ margin-left:2em; }
		.requestDemo p{ margin:1.5em 1.5em 1.5em 0; }
	}
	
	@include media-breakpoint-up(md) { 
		p{ margin:2em 0 2em 4.2em; }
		.requestDemo p{ margin:1.5em 3em 1.5em 1em; } 
	}
}
.addlCustomContent{ font-size:1.2em; font-weight:300; 
	p{ margin:1.4em 0; }
	.btn-secondary{ margin-top:.6em; }
}
.divider{ line-height: 140px; font-size: 37px; font-weight: 200; }


/*
	courseGroup
 */
.courseGroup{ padding-top:2rem; padding-bottom:2rem; margin-bottom:.7em; 
	background:url('/assets/images/bokeh1.jpg') 0 0 no-repeat transparent; background-size:cover;
	&:nth-of-type(2){ background-image:url('/assets/images/bokeh2.jpg'); }
	&:nth-of-type(3){ background-image:url('/assets/images/bokeh3.jpg'); }
	&:nth-of-type(4){ background-image:url('/assets/images/bokeh4.jpg'); }
	&:nth-of-type(5){ background-image:url('/assets/images/bokeh5.jpg'); }
	&:nth-of-type(6){ background-image:url('/assets/images/bokeh6.jpg'); }
	&:nth-of-type(7){ background-image:url('/assets/images/bokeh6.jpg'); 
		li{ font-weight:300; }
	}
	
	& > [class^='col-']{ background:#fff; padding-top:1rem; }
	h2{ font-size:2rem; margin:0 0 0 1rem; 
		@include media-breakpoint-only(sm) { font-size:1.5rem; }
		@include media-breakpoint-up(xl) { font-size:2.6rem;margin-left:1.5rem; }
	}
	p{ font-weight:300; }
	ul{ margin-left:2rem; 
		@include media-breakpoint-only(xs){ font-size:16px; }
		@include media-breakpoint-only(lg){ margin-left:1rem; }
	}
	a{ color:$primaryColor2; font-weight:300; }
	.courseGroupTitle{
		justify-content:center;
		p{ margin-left:1rem; font-style: italic; line-height: 1.2; margin-right:1.5rem;
			@include media-breakpoint-up(md) { margin-right:3rem; }
			@include media-breakpoint-up(xl) { margin-left:1.5rem; }
		}
	}
	.courseGroupTagline{ margin-right:1rem; 
		p{ margin-left:.8rem; }
	}
	
	//borders - The border stops before the edge of the box, so to prevent us from having to use weird nesting, use a gradient with border-image
	.courseGroupTitle,
	.courseGroupTagline{ border-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 5%, #ddd 5%, #ddd 95%, rgba(0,0,0,0) 95%, rgba(0,0,0,0) 100%) 1 100%; }
	.courseGroupTitle{	border-width: 0 1px 0 0; }
	.courseGroupTagline{ border-width: 0 0 0 1px; }
} 


 /*
	course page
 */
 .courseImg{ width:100%; margin:1em 0;
	@include media-breakpoint-up(sm) { width: 35%; float: right; margin: 0 2em; }
	@include media-breakpoint-up(lg) { margin:4em 0 0; width:100%; }
}
@include media-breakpoint-down(md) { 
	.btnRequest{ width:100%; display:block; } 
	.btnRequest+.btnRequest{ margin-top:5px; }
}

/****************************************************************************************
	ANIMATIONS
*****************************************************************************************/	
	//used on onlinecourses, example 3
	@keyframes bouncebtn {
	  0%,20%,50%,80%,100%{ padding-bottom:0%; }
	  40% { padding-bottom:1%; }
	  60% { padding-bottom:.5%; }
	}
	//used on onlinecourses, example 3
	@keyframes glow {
	  0%,100%{ box-shadow:0 0 0 $secondaryColor1; }
	  50% { box-shadow:0 0 10px $secondaryColor1; }
	}
	//the gears svg icon, the lightbulb svg icon
	@keyframes spin {
	  0%{ transform: rotate(0deg); }
	  100%{ transform: rotate(360deg); }
	}
	//the gears svg icon
	@keyframes spinReverse {
	  0%{ transform: rotate(360deg); }
	  100%{ transform: rotate(0deg); }
	}
	//the rocket svg icon (without spotlight lines)
	@keyframes flyaway {
	  0%{ transform: translate(0, 0); }
	  40%{ transform: translateY(-22px) rotate(30deg); }
	  100%{ transform: translate(145px,-135px); }
	}
	//rocket svg (with spotlight lines)
	@keyframes flyawaywSpot {
	  0%{ transform: translate(0, 0); }
	  30%{ transform: translateY(-22px) rotate(30deg); }
	  100%{ transform: translate(650px,-600px); }
	}
	


/****************************************************************************************
	EXAMPLES
*****************************************************************************************/	
	.example{ background: no-repeat 0 0 transparent; min-height:180px; overflow:auto; margin-bottom:.7em;  position:relative; padding-top:1em; padding-bottom:1em; 
		
		@include media-breakpoint-up(md){ background-size:cover; height:0; padding-top:0; padding-bottom:22.5%;  }
		
		.exampleText{ position:static;
			@include media-breakpoint-up(md){ position:absolute; top:2.4em; left:0; }
			h3{margin-bottom:.3rem; }
			p{ margin-top:1em; margin-bottom:.5rem; 
				@include media-breakpoint-up(sm){ margin-top:0; }
			}
			.btn{ padding:.1rem 1rem .3rem; }
			
			@include media-breakpoint-up(xl){ p{ margin-bottom:.9rem; } }
		}
		
		
		&.expandable{ width:100%; overflow:hidden;
			transition-property: padding;
	    	transition-duration: 1s; 
	    	
	    	&.expandableVideo{
		    	img{ max-width:none; transition-property:width,margin; transition-duration:1s; 
			    	margin-left:-117%; width: 234%;
			    	@include media-breakpoint-up(md){ width:111%; margin-left:-6%;  }
			    	@include media-breakpoint-up(lg){ width:100%; margin-left:0;  }
		    	}
			    &.expanded{ padding-bottom:56.25%; /* aspect 16:9 */ 
					img{ width:250%; margin-left:-125%; }
				}
	    	}
	    }
	}
	
	#videoContainerCC2 img{ margin-left:0; }	
	   
	
	#videoContainerCC1.expanded img{ margin-left:-25%; }
	#videoContainerCC3.expanded img{ margin-left: -25%; width: 292%; position: relative; top: -57px; }
	#videoContainerEM2.expanded img{ margin-left:-25%; }
	
	@include media-breakpoint-down(sm){
		#videoContainerCC1{ background-image:url('/assets/images/ex_wmsburgHealth.jpg'); background-position-x: -600px; }
		#videoContainerCC2{ background-image:url('/assets/images/ex_brutonParish.jpg'); }
		#videoContainerCC3{ background-image:url('/assets/images/ex_algebra.jpg'); background-position-x: -600px; }
		#exampleNazi{ background-image:url('/assets/images/ex_nazi.jpg'); }
		#exampleHistory{ background-image:url('/assets/images/ex_vausHistory.jpg'); }
		#videoContainerEM1{ background-image:url('/assets/images/ex_nazi.jpg');  }
		#videoContainerEM2{ background-image:url('/assets/images/ex_elephant.jpg'); background-position-x: -600px; }
		#videoContainerEM3{ background-image:url('/assets/images/ex_ph.jpg'); }
	}
	
	#exampleAstronomy{ background-image:url('/assets/images/ex_astronomy.jpg'); 
		@include media-breakpoint-only(md){ p{ margin-right:30px; } }
		@include media-breakpoint-only(xl){ p{ margin-right:60px; } }
	}
	#exampleBiology{ 
		background-image:url('/assets/images/ex_biology.jpg'); 
		@include media-breakpoint-only(xs){ background-repeat:repeat; }
		@include media-breakpoint-down(sm){ background-position-x: -600px; }
	}
	#exampleHistory{ 
		.exampleText{ top:1em; } 
		p{ margin-right:70px; }
	}
	#videoContainerCC1,
	#videoContainerCC2,
	#videoContainerCC3{ .exampleText{ top:0.5em; } }
	
	#videoContainerEM1{ .exampleText{ top:1em; } p{margin-right:60px;} }
	#videoContainerEM2 .exampleText{ top:1em; }
	#videoContainerEM3 .exampleText{ top:1em; }
	
	#exampleAstronomy .exampleText{ top:1em; }
	#exampleBiology .exampleText{ top:1em; }
	
	.example .exampleText h3{ font-size:1.5em }
	@include media-breakpoint-only(md){ 
		.example .exampleText h3{ font-size:1.4em } 
		#videoContainerCC1,
		#videoContainerCC2,
		#videoContainerCC3{ .exampleText h3{ font-size:1em; } }
	}
	@include media-breakpoint-up(lg){ 
		.example .exampleText h3{ font-size:1.25em 	 }
		#videoContainerEM1 .exampleText{ top:1.5em; }
		#videoContainerEM3 .exampleText{ top:2.4em; }
	}
	@include media-breakpoint-up(xl){ 
		#videoContainerCC1 .exampleText{ top:1.7em; }
		#videoContainerCC2,
		#videoContainerCC3{ .exampleText{ top:3em; 	 } }
		#videoContainerEM1 .exampleText{ top:3.8em; }
		#videoContainerEM2 .exampleText{ top:3em;   }
		
		#exampleAstronomy .exampleText{ top:2.4em; }
		#exampleBiology .exampleText{ top:2.4em; }
		#exampleHistory   .exampleText{ top:3.4em; }
	}
	
	#homeExample2{ background-image:url('/assets/images/ex_wmsburgHealth.jpg'); }
		/* Minor edits we normally wouldn't do, but it's a static site, so ok. */
		#homeExample2{ 
			.exampleText{top:2em;} 
			p{ text-align:left }
		
			@include media-breakpoint-down(sm){ background-position-x: -600px; }	
			@include media-breakpoint-up(md){ p{ margin-left:7em; } }
			@include media-breakpoint-up(lg){ .exampleText{top:2.8em;}  p{ margin-left:4em; } }
			@include media-breakpoint-only(xl){ p{ margin-left:6em; } }
		}
	#exampleNazi{
		.exampleText{ top:2em; 
			@include media-breakpoint-up(xl){ top:4em; }
		}
		
		img{ max-width:none; }
	}
	
	.bounce{
		background-color:rgb(217,184,139);
		position:relative; width:100%; overflow:hidden; padding-top:1em; padding-bottom:1em; 
		@include media-breakpoint-up(md){ background-size:cover; height:0; padding-top:0; padding-bottom:22.5%;  }
		
		
		
		
		@include media-breakpoint-up(md){ padding-bottom:22.5%; }
		transition-property: padding;
	    transition-duration: 2s; 
		
		.exampleText{ transition-property: padding,background-color; transition-duration: 2s;  }
		.expandableContent{ position:absolute; top:2em; left:2em; }
	    img{ width:100%; }
		
		.btnExpand{ position:absolute; bottom:0; left:50%;  background:#fff; color:$secondaryColor1; width:190px; margin-left:-95px; border-top-left-radius:5px; border-top-right-radius:5px;  cursor:pointer; padding-right:15px;  
			.svgArrow{ position:absolute; right:5px; top:0; }
			
			@include media-breakpoint-down(sm){ left:auto; right:2%; }
		}
		
	    &:hover .btnExpand{ animation: glow 2s 3,bouncebtn 1.5s ease-out 2; }
	    
	    img.fullImage{ position: relative; top: -759px; transform: scale(.6); left: 300px; opacity:0;
			transition-property: all;
	    	transition-duration: 2s; 
	    }
	    img.clipImage{ position:absolute; top:0; left:0; opacity:100;
		    transition-property: all;
	    	transition-duration: 2s; 
	    	
	    	max-width: none; width: 200%;
	    	
	    	@include media-breakpoint-only(md){ width:111%; margin-left:-3%; }
	    	@include media-breakpoint-up(lg){ width:100%; }
	    }
	    &.expanded{ padding-bottom:160% }
	    &.expanded img.fullImage{ top:80px; left:0; transform: scale(.8);  opacity:100;
		    @include media-breakpoint-only(sm){ top:50px; }
			@include media-breakpoint-only(md){ top:-141px; }
			@include media-breakpoint-only(lg){ top:-183px; }
		    @include media-breakpoint-only(xl){ top:-234px; }
	    }
	    &.expanded img.clipImage{ opacity:0; top:393px; left:-427px; transform:scale(1.4) }
	    @include media-breakpoint-up(md){
		    &.expanded .exampleText{ background-color:rgba(0,0,0,.8); padding:1em; }
		}
	    &.expanded .svgArrow{ transform:scaleY(-1) }
	}



/****************************************************************************************
	SVG
*****************************************************************************************/	

.svgCircleArrow{ 
	background:$primaryColor1; border:2px solid $primaryColor1; border-radius:50%; 
	.cls-1 { fill: $primaryColor1; stroke-width: 5px; }
	.cls-1, 
	.cls-2 { stroke: $primaryColor1; stroke-linejoin: round; }
	.cls-2 { fill: #fff; stroke-width: 3px; fill-rule: evenodd; }
	
}
.separator .bkgdPrimary3+span .svgCircleArrow{
	background:$primaryColor3; border:2px solid $primaryColor3; border-radius:50%; 
	.cls-1{ fill:$primaryColor3; }
	.cls-2{ fill:$primaryColor2; }
	.cls-1, 
	.cls-2 { stroke: $primaryColor3;  }
}

#svgGearswSpot{
	&:hover {
		#largeGearGroup{ animation: spin 5s linear infinite; transform-origin:338px 240px;}
		#smallGearGroup{ animation: spinReverse 5s linear infinite; transform-origin:444px 134px;}
	}
}
#svgGears{
	&:hover {
		.largeGear
		,.largeGear-outsideLeft
		,.largeGear-insideRight{ animation: spin 5s linear infinite; transform-origin:58px 79px; }
		
		.smallGear
		,.smallGearLeft{ animation: spinReverse 5s linear infinite; transform-origin:93px 44px; }
	}
}
#svgLightwSpot{
	&:hover .lightbulb-rays{ animation: spin 10s linear infinite; transform-origin: 376px 196px;
		.cls-4{fill:#fff}
	}
}
#svgLight{
	&:hover .lightbulb-rays{ animation: spin 10s linear infinite; transform-origin: 70px 65px;
		.cls-4{fill:#fff}
	}
}
#svgRocketwSpot{
	/* this has to be set or the rocket won't be reset on mouseout */
	#svgRocket-rocket{ transform: translateY(0); }
	#cloud{ display:none }
	&:hover{
		.shadow{ display:none; }
		#cloud{ display:block }
		#svgRocket-rocket{ animation: flyawaywSpot 5s ease-in 1; transform-origin: 600px 272px; }
	}
}

#svgRocket{
	/* this has to be set or the rocket won't be reset on mouseout */
	#svgRocket-rocket{ transform: translateY(0); }
	#cloud{ display:none }
	&:hover{
		.shadow{ display:none; }
		#cloud{ display:block }
		#svgRocket-rocket{ animation: flyaway 5s ease-in 1; transform-origin: 70px 72px; }
	}
}


